import React, { useState } from 'react'

import { Dialog } from '@headlessui/react'
import { Close } from 'components/Icons/Icons'

import { QuestionForm } from 'components/Idengager'

type QuestionFormProps = {
    onSubmit: () => void
}

export default function Questions(props: QuestionFormProps): JSX.Element {
    const [showModal, setShowModal] = useState(false)

    const handleSubmit = () => {
        props.onSubmit()
        setShowModal(false)
    }

    return (
        <>
            <Dialog open={showModal} onClose={(isOpen) => setShowModal(isOpen)}>
                <div className="fixed inset-0 z-40 bg-black opacity-30" />

                <div className="fixed inset-0 z-50 flex items-center justify-center">
                    <Dialog.Panel className="w-full max-w-2xl rounded bg-tan p-4 shadow dark:bg-gray-accent-dark dark:shadow-none">
                        <div className="mb-3 flex items-center justify-between">
                            <Dialog.Title className="m-0 text-2xl font-bold">Ask a question</Dialog.Title>
                            <button className="p-2" onClick={() => setShowModal(false)}>
                                <Close className="h-3 w-3" />
                            </button>
                        </div>
                        <QuestionForm
                            slug={(typeof window !== 'undefined' && window.location.pathname) || ''}
                            initialView="question-form"
                            formType="question"
                            onSubmit={handleSubmit}
                        />
                    </Dialog.Panel>
                </div>
            </Dialog>
            <button
                type="button"
                className="inline-flex flex-shrink-0 items-center justify-center rounded border border-transparent bg-red px-4 py-2 text-sm font-medium text-white shadow-sm shadow-lg focus:outline-none focus:ring-2 focus:ring-red focus:ring-offset-2 sm:w-auto"
                onClick={() => setShowModal(true)}
            >
                Ask a question
            </button>
        </>
    )
}
