import { MDXProvider } from '@mdx-js/react'
import { BorderWrapper } from 'components/BorderWrapper'
import { Caption } from 'components/Caption'
import { FloatedImage } from 'components/FloatedImage'
import { ImageBlock } from 'components/ImageBlock'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import React, { useEffect, useState } from 'react'
import { shortcodes } from '../../mdxGlobalComponents'
import Link from 'components/Link'
import { animateScroll as scroll } from 'react-scroll'
import { ViewButton } from '../../templates/tutorials/Tutorial'
import { Video } from 'components/NotProductIcons'
import { motion } from 'framer-motion'
import { MenuContainer } from 'components/PostLayout/MobileNav'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { InlineCode } from 'components/InlineCode'
import { Blockquote } from 'components/BlockQuote'
import { MdxCodeBlock } from 'components/CodeBlock'
import { ZoomImage } from 'components/ZoomImage'

const A = (props) => <Link {...props} className="font-semibold text-red hover:text-red" />

interface IProps {
    content: {
        title: string
        image?: string
        body: string
        author?: string
        tags?: string[]
        video?: string
    }[]
    title?: string
    initialIndex?: number
}

export default function ContentViewer({ content, title, initialIndex }: IProps) {
    const [currentIndex, setCurrentIndex] = useState<number | null>(initialIndex ?? null)
    const [contentView, setContentView] = useState('Article')
    const currentContent = currentIndex !== null && content[currentIndex]
    const breakpoints = useBreakpoint()
    const ContentContainer = breakpoints.sm ? MenuContainer : React.Fragment
    const components = {
        ...shortcodes,
        BorderWrapper,
        Caption,
        ImageBlock,
        FloatedImage,
        a: A,
        inlineCode: InlineCode,
        blockquote: Blockquote,
        pre: MdxCodeBlock,
        MultiLanguage: MdxCodeBlock,
        img: ZoomImage,
    }

    useEffect(() => {
        if (breakpoints.sm !== undefined) {
            setCurrentIndex(breakpoints.sm ? null : initialIndex ?? 0)
        }
    }, [breakpoints])

    return (
        <div className="relative flex gap-x-6 lg:gap-x-12">
            <motion.div className="md:flex-[0_0_350px]">
                <div className="sticky top-4">
                    {title && <h3 className="mb-2 text-lg">{title}</h3>}
                    <ul className="m-0 grid list-none gap-y-1 p-0">
                        {content.map(({ title, author, image, tags, video }, index) => {
                            const active = currentIndex === index
                            const hasTags = tags && tags.length
                            return (
                                <li key={title + index}>
                                    <button
                                        onClick={() => {
                                            setCurrentIndex(index)
                                            scroll.scrollToTop()
                                        }}
                                        className={`relative w-full rounded-md p-4 text-left hover:top-[-.5px] hover:scale-[1.01] hover:bg-gray-accent-light active:top-[.5px] active:scale-[1] ${
                                            active ? 'bg-gray-accent-light hover:top-[0px] hover:scale-[1]' : ''
                                        }`}
                                    >
                                        {image && <img className="mb-2 max-h-[30px]" src={image} />}
                                        {(hasTags || video) && (
                                            <div className="flex flex-col-reverse items-start justify-between space-y-2 space-y-reverse lg:flex-row lg:space-x-2 lg:space-y-0">
                                                {hasTags && (
                                                    <ul className="m-0 mb-1 flex list-none flex-wrap items-center p-0">
                                                        {tags.map((tag) => {
                                                            return (
                                                                <li
                                                                    className="mr-1 mb-1 whitespace-nowrap rounded-full bg-red/10 px-2 py-1 text-xs text-red"
                                                                    key={tag}
                                                                >
                                                                    {tag}
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                )}
                                                {video && (
                                                    <Video className="absolute right-4 top-3 w-6 flex-shrink-0 opacity-50 lg:relative" />
                                                )}
                                            </div>
                                        )}

                                        <p className="m-0 text-[15px] font-semibold leading-tight">{title}</p>
                                        {author && (
                                            <p className="m-0 mt-1 text-sm font-semibold opacity-60">by {author}</p>
                                        )}
                                    </button>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </motion.div>
            {currentContent && (
                <div className="article-content z-[999999999999999] md:z-auto md:flex-1">
                    <ContentContainer {...(breakpoints.sm ? { setOpen: setCurrentIndex } : {})}>
                        <div className="max-h-[70vh] overflow-auto md:max-h-[initial]">
                            <h1 className="mb-6 text-xl md:text-2xl">{currentContent.title}</h1>
                            {currentContent.video && (
                                <div className="mb-6 flex space-x-2">
                                    <ViewButton view={contentView} title="Article" setView={setContentView} />
                                    <ViewButton view={contentView} title="Video" setView={setContentView} />
                                </div>
                            )}
                            {currentContent.video && contentView === 'Video' ? (
                                <iframe src={currentContent.video} />
                            ) : (
                                <MDXProvider components={components}>
                                    <MDXRenderer>{currentContent.body}</MDXRenderer>
                                </MDXProvider>
                            )}
                        </div>
                    </ContentContainer>
                </div>
            )}
        </div>
    )
}
